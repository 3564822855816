@charset "UTF-8";


// ==================================================
//  Element
// ==================================================
html {
  font-size: 62.5%;
}
body {
  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
  }
}
a {
  &:hover {
    text-decoration: none;
  }
}
img {
  max-width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
}
strong {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border: none;
  border-spacing: 0;
}
button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}