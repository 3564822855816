@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "index";
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Hide
// ==================================================
.hide {
  &-pc {
    @include mixin.pc {
      display: none !important;
    }
  }
  &-sp {
    @include mixin.sp {
      display: none !important;
    }
  }
}

// ==================================================
//  Fonts
// ==================================================
@font-face {
  font-family: "LINESeedJP";
  font-weight: 300;
  src: local("LINESeedJP_OTF"), url("../../data/font-line-speed-jp/woff2/LINESeedJP_OTF_Th.woff2") format("woff2");
}
@font-face {
  font-family: "LINESeedJP";
  font-weight: 400;
  src: local("LINESeedJP_OTF"), url("../../data/font-line-speed-jp/woff2/LINESeedJP_OTF_Rg.woff2") format("woff2");
}
@font-face {
  font-family: "LINESeedJP";
  font-weight: 600;
  src: local("LINESeedJP_OTF"), url("../../data/font-line-speed-jp/woff2/LINESeedJP_OTF_Bd.woff2") format("woff2");
}
@font-face {
  font-family: "LINESeedJP";
  font-weight: 900;
  src: local("LINESeedJP_OTF"), url("../../data/font-line-speed-jp/woff2/LINESeedJP_OTF_Eb.woff2") format("woff2");
}

// ==================================================
//  Body
// ==================================================
body {
  font-family: "LINESeedJP", "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", YuGothic, "Yu Gothic", Meiryo, Verdana, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.66;
  min-width: 1080px;
  background-color: color.$site-bg;

  @include mixin.sp {
    font-size: 1.3rem;
    line-height: 1.54;
    min-width: 375px;
  }
}

// ==================================================
//  Main
// ==================================================
main {
  display: block;
  width: 100%;
  padding: 130px 0 0;

  @include mixin.sp {
    padding-top: 110px;
  }
}

// ==================================================
//  Header
// ==================================================
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: 1080px;
  padding: 30px 50px 30px 40px;
  position: fixed;
  z-index: 80;
  top: 0;
  left: 0;

  @include mixin.sp {
    min-width: 375px;
    padding: 20px;
  }

  &_logo {
    display: block;
    width: 140px;
    margin: 0;

    @include mixin.sp {
      width: 106px;
    }

    a,
    img {
      display: block;
      width: 100%;
    }
    a {
      @include mixin.hoverOpacity;
    }
  }

  &_navigation {
    &_menu {
      &_list {
        display: flex;
        column-gap: 32px;
        list-style: none;
        margin: 0;
        padding: 5px 0 0;

        @include mixin.sp {
          padding-top: 7px;
          column-gap: 16px;
          padding: 4px 0 0;
        }

        &_item {
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 1.17;
          white-space: nowrap;

          @include mixin.pc {
            letter-spacing: 0.05em;
          }

          @include mixin.sp {
            font-size: 1.3rem;
            line-height: calc(21 / 13);
          }

          &_inner {
            text-decoration: none;

            @include mixin.hover {
              &::after {
                width: 100%;
              }
            }

            &::after {
              @include mixin.pc {
                content: "";
                display: block;
                width: 0;
                height: 3px;
                background-color: color.$write-main;
                margin-top: 3px;
                transition: width 0.3s;
              }
            }
          }
        }
      }
    }
  }
}

// ==================================================
//  Footer
// ==================================================
.footer {
  display: block;
  width: 100%;
  padding: 120px 0 70px;

  @include mixin.sp {
    padding: 80px 0 30px;
  }

  &_copyright {
    font-size: 1.5rem;
    line-height: calc(24 / 15);
    margin: 0 0 0;
    text-align: center;

    @include mixin.sp {
      font-size: 1.2rem;
    }
  }
}

// ==================================================
//  PageButtonLink
// ==================================================
.pageButtonLink {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  column-gap: 20px;
  width: 100%;
  max-width: 900px;
  margin: 100px auto 0;

  @include mixin.sp {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: calc(100% - 40px);
    column-gap: 0;
    row-gap: 20px;
    margin-top: 80px;
  }

  // Modifier
  &-vertical {
    @include mixin.pc {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      row-gap: 30px;
    }
  }

  &_item {
    display: block;
    width: 100%;
    max-width: 320px;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 600;
    text-align: center;
    border: 2px solid color.$write-main;
    text-decoration: none;
    padding: 22px 0 20px;
    border-radius: 70px;
    transition: color 0.3s, background-color 0.3s;

    @include mixin.hover {
      color: color.$write-white;
      background-color: color.$write-main;
    }

    @include mixin.sp {
      max-width: 250px;
      font-size: 1.3rem;
      padding: 16px 0 15px;
      border-radius: 27px;
    }
  }
}

// ==================================================
//  PageTitle
// ==================================================
.pageTitle {
  font-size: 5rem;
  font-weight: 600;
  line-height: calc(81 / 50);
  letter-spacing: 0.05em;
  margin: 0;

  @include mixin.sp {
    font-size: 3.2rem;
    line-height: calc(52 / 32);
  }

  // Modifier
  &-center {
    text-align: center;
  }
}

// ==================================================
//  PageText
// ==================================================
.pageText {
  font-size: 1.6rem;
  line-height: 2.375;
  margin: 20px 0 0;

  @include mixin.sp {
    font-size: 1.3rem;
    line-height: 2;
    margin-top: 15px;
  }

  // Modifier
  &-center {
    text-align: center;
  }
}

// ==================================================
//  PageContentArea
// ==================================================
.pageContentArea {
  display: block;
  width: 100%;
  max-width: 1040px;
  margin: 40px auto 0;

  @include mixin.sp {
    max-width: calc(100% - 20px);
    margin-top: 30px;
  }

  & > :first-child {
    margin-top: 0;
  }
}

// ==================================================
//  PagePost
// ==================================================
.pagePost {
  display: flex;
  flex-wrap: wrap;
  column-gap: 55px;
  row-gap: 100px;
  width: calc(100% - 120px);
  max-width: 1280px;
  margin: 156px auto 0;

  @include mixin.sp {
    column-gap: 0;
    row-gap: 50px;
    width: 100%;
    max-width: calc(100% - 40px);
    margin-top: 20px;
  }

  &_item {
    width: calc(100% / 3 - 110px / 3);

    @include mixin.sp {
      width: 100%;
    }

    &_inner {
      display: block;
      width: 100%;
      text-decoration: none;

      @include mixin.hover {
        .pagePost_item {
          &_image {
            &::after {
              opacity: 0.4;
            }

            & > img {
              transform: translateY(-50%) scale(1.05);
            }
          }
        }
      }
    }

    &_image {
      display: block;
      width: 100%;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      padding-top: 66.6666%;

      @include mixin.sp {
        border-radius: 6px;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: color.$write-main;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.75s;
      }

      & > img {
        display: block;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%) scale(1);
        transition: transform 0.6s;
      }
    }

    &_title {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: calc(29 / 18);
      margin: 16px 0 0;

      @include mixin.sp {
        font-size: 1.3rem;
        line-height: 1.54;
      }
    }

    &_category {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 10px;
      margin: 4px 0 0;

      @include mixin.sp {
        column-gap: 6px;
        row-gap: 4px;
        margin-top: 4px;
      }

      &_item {
        display: block;
        font-size: 1.4rem;
        line-height: calc(22 / 14);

        @include mixin.sp {
          font-size: 1.1rem;
          line-height: 1.55;
        }
      }
    }
  }
}

// ==================================================
//  PagePagination
// ==================================================
.pagePagination {
  display: block;
  width: 100%;
  margin: 100px auto 0;

  @include mixin.sp {
    width: calc(100% - 40px);
    margin-top: 70px;
  }

  // WordPress
  .pagination {
  }
  .pagination .screen-reader-text {
    display: none;
  }
  .pagination .nav-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 12px;
    row-gap: 12px;

    @include mixin.sp {
      column-gap: 10px;
      row-gap: 10px;
    }
  }
  .pagination .nav-links .page-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: 600;
    border-radius: 100%;

    @include mixin.sp {
      width: 48px;
      height: 48px;
      font-size: 1.4rem;
    }

    &:not(.dots) {
      border: 2px solid color.$write-main;
      transition: color 0.3s, background-color 0.3s;

      @include mixin.hover {
        color: color.$write-white;
        background-color: color.$write-main;
      }
    }
  }
  .pagination .nav-links .current {
    color: color.$write-white;
    background-color: color.$write-main;
  }
  .pagination .nav-links .dots {
    @include mixin.sp {
      width: 16px;
    }
  }
  .pagination .nav-links a.page-numbers {
  }
  .pagination .nav-links a.page-numbers:hover {
  }
  .pagination .nav-links a.prev.page-numbers,
  .pagination .nav-links a.next.page-numbers {
    display: none;
  }
  .pagination .nav-links a.prev.page-numbers:hover,
  .pagination .nav-links a.next.page-numbers:hover {
  }
}
